@import "src/style/abstracts/_abstract.scss";

.list-avatar{
  align-self: center;
  justify-self: center;
}
.list-header{
  margin: 5px;
  padding: 0;
  small{
    font-size: 0.8em;
    margin-left: 7px;
    color: tint($palette-dark, 40);
  }
}
.list-container{
  margin-top: 5px;
  margin-bottom: 5px;
}

.list-content{
  color: tint($palette-dark, 20);
}

.list-avatar-container{
  background-color: tint($palette-dark, 85)!important;
}

.list-content-list{
  padding: 10px;
  margin: 6px;
  font-size: 0.8em;
  .list-content-list-item{
    .list-content-list-item-header{
      margin: 0;
      padding: 0;
      font-size: 1.1em;
    }
  }

}

.list-content-list-columns{
  .list-content-list-item{
    width: fit-content;
  }
}
