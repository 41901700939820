@import "src/style/abstracts/_abstract.scss";

.dashboard{
  display: grid;
  background-color: mix(#062653, $palette-light, 5);
  grid-template-columns: 1fr 5fr;
  border-radius: 0 0 10px 10px;
  @include respond-between('phone', 'pc') {
    grid-template-columns: 1fr;
    border-radius: 0;
  }
}

.dashboard-closed{
  grid-template-columns: 1fr!important;

}
.dashboard-left-panel-closed{
  display: block;
  @include respond-between('phone', 'pc') {
    display: none;
  }
}

.dashboard-left-panel{
  @include respond-between('phone', 'pc') {
    position: fixed;
    display: none;
  }
}
.dashboard-left-panel-opened{
  @include respond-between('phone', 'pc') {
    display: block;
    z-index: 15;
    top: 0;
    background-color: $palette-light;
    height: 100vh;
    overflow: auto;
  }
}

.dashboard-main{
  padding: 30px;
  @include respond-between('phone', 'pc') {
    padding: 7px;
  }
}

.overlay-dashboard{
  z-index: 10;
  position: fixed;
  overflow: hidden;
  top: 0;
  left:0;
  width: 100%;
  overflow: auto;
  height: 100vh;
  background-color: $palette-dark;
  opacity: 0.3;
}
