@import "src/style/abstracts/abstract";


.list{
  background-color: $palette-light;
}

.MuiListItem-root{
  min-height: 160px;
}

.user-activated-chip {
  background-color: $palette-green!important;
  color: $palette-light!important;
}
.user-deactivated-chip {
  background-color: $palette-terciary!important;
  color: $palette-light!important;
}
