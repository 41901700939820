@import "src/style/abstracts/_abstract.scss";


.overlay{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0.7;
  background-color: $palette-light;
  z-index: 30;
}

.overlay-text-container{

}

.overlay-text{
  position: absolute;
  top: 40%;
  z-index: 2;
  width: 100%;
  height: 100vh;
}
