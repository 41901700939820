@import "src/style/abstracts/_abstract.scss";


.lead-sale-chip {
  background-color: $palette-green!important;
  color: $palette-light!important;
}
.form-filters{
  padding: 12px;
  background-color: tint($palette-dark, 95);
  border-radius: 7pt;
  margin: 7px;
  h3{
    margin: 8px;
    padding: 0;
  }
}


.form-filters-text{
  input{
    background-color: $palette-light!important;
  }
}


.filter-leads-pagination{
  align-self: self-end;
}
