@import "src/style/abstracts/_abstract.scss";


.unloaded-app-appear{
  opacity: 0.01;
}

.unloaded-app-appear.unloaded-app-appear-active {
  opacity: 1;
  transition: opacity 0.8s ease-in;

}

.unloaded-app-load{
  position: absolute;
  top: 40%;
  background-color: rgba($palette-light, 10);
  border-radius: 30px;
  padding: 15px;
  z-index: 2;
  left: 50%;
  transform: translate(-50%, -50%);
  color: $palette-light;
  font-size: 3em;
}
.unloaded-app-load-header-logo{
  z-index: 2;
  max-width: 5em;
}

.unloaded-app-load-spinner{
  position: absolute;
  z-index: -1;
  top: 0;
  width: 100%;
  left: 0;
}
