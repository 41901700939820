@import "src/style/abstracts/_abstract.scss";


main{
  background-color: shade($palette-light, 5);
  min-height: 80vh;
  border-radius: 10px 10px 10px 10px;
  @include respond-between('phone', 'pc') {
    border-radius: 0;
  }
}
