@import "src/style/abstracts/_abstract.scss";

.dialog-close-icon{
  font-size: 1.7em;
  color: #c65663;
  float: right;
  margin: 6px;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
  &:hover{
    color: #ff8d9b;
  }
}

.MuiDialog-paper{
  flex-grow: 1!important;
}
