@import "src/style/abstracts/_abstract.scss";


.home-dashboard{
  display: grid;
  padding: 15px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 3%;
  grid-row-gap: 10px;
  @include respond-between('phone', 'pc') {
    grid-template-columns: 1fr;
  }
}

.home-info{
  img{   display: table;
    margin: 25px auto;
      width: 80%;
  }
}

.home-info-editor-container{
  margin-top: 25px!important;
  margin-bottom: 25px !important;
  display: block !important;
  height: auto;
}

.editor{
  padding: 6px 5px 0;
  border-radius: 2px;
  border: 2px solid #062653;
  display: flex;
  justify-content: flex-start;
  background: white;
  flex-wrap: wrap;
  font-size: 15px;
  margin-bottom: 5px;
}

.toolbar{
  border: 2px solid #062653 !important;
}

.editButton{
  background-color: #ffc107 !important;
  color: $palette-light !important;
}

.discardButton{
  margin-left: 20px !important;
  background-color: #dc3545 !important;
  color: $palette-light!important;
}


.saveButton{
  margin-left: 20px !important;
  background-color: #28a745 !important;
  color: $palette-light!important;
}

.editorButtons{
  margin: 25px auto;
  column-gap: 4%;
}


