
@import "src/style/abstracts/abstract";

  .register-user-form-block{
    & > div{
      margin: 5px;
    }
  }

.MuiFormControl-root{
  width: 100%!important;
}

.register-user-button{
  width: max-content;
  margin: 30px auto !important;
  display: table!important;
}
