

@import "src/style/abstracts/_abstract.scss";


.lead-details-pdf-button-download{
    display: block;
    text-decoration: none!important;
    color: $palette-dark!important;
    float: right;
}
