@import "src/style/abstracts/_abstract.scss";


.footer {
  height: 0;
  font-size: 0.8em;
  margin-left: 12%;
  margin-top: 30px;
  @include respond-between('phone', 'pc') {
    left: 15px;
    font-size: 1em;
  }
  ul{
    color: $palette-light;
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    @include respond-between('phone', 'pc') {
      display: block;
    }
    li{
      list-style: none;
      @include respond-between('phone', 'pc') {
        display: block;
        margin-top: 15px;
      }
    }
  }
}
