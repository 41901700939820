@import "src/style/abstracts/_abstract.scss";


.date-range-week-picker-month{
  &:last-child{
    border: 0;
  }

}
.date-range-week-picker-container{
  padding: 15px;
  box-shadow: box-shadow('medium');
  display: block;
  background-color: $palette-light;
  width: fit-content;
  flex-direction: row;
}
.date-range-week-picker-header{
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  padding: 16px 16px 8px 16px;
  align-items: center;
  justify-content: space-between;
}

.date-range-week-picker-calendar{
  padding: 10px;
}
