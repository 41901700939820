@import "src/style/abstracts/_abstract.scss";

.summary-content{
  margin: 15px;
  background:tint($palette-dark, 98);
  padding: 15px;

}

h2, h3 {
  margin: 10px;
  font-weight: bold;

}

.summary-stats-table{
  background-color: $palette-light;
  border: 1px solid tint($palette-dark, 40);
  table-layout: fixed;
  font-size:1.1em;
  border-collapse: initial;
  @include respond-between('phone', 'pc') {
    width: auto!important;
  }
  th{
    font-weight: bold;
  }
  .my-stands-total-results{
    th{
      background-color: tint($palette-dark, 96);
    }
  }

}

.my-stands{
  h1,h2,h3,h4,h5{
    margin-top: 30px;
    margin-bottom: 30px;
  }
}
.stand-opened-chip {
  background-color: $palette-green!important;
  color: $palette-light!important;
}
.stand-closed-chip {
  background-color: $palette-terciary!important;
  color: $palette-light!important;
}

.my-stands-stand{
  padding: 15px;
  @include respond-between('phone', 'pc') {
    overflow-x: auto;
    max-width: 90vw;
  }
  //background-color: tint($palette-dark, 96);
}


.my-stands-table-container{
  overflow: hidden;
  @include respond-between('phone', 'pc') {
    table{
      table-layout: auto;
      width: max-content!important;
    }
    max-width: 90vw;
    overflow-x:scroll;
  }
}
