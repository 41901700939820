/// Gestor Responsive
/// @access public
/// @param {String} $breakpoint - Punto de ruptura
/// @requires $breakpoints
@mixin respond-to($breakpoint) {
  $raw-query: map-get($breakpoints, $breakpoint);

  @if $raw-query {
    $query: if(
        type-of($raw-query) == 'string',
        unquote($raw-query),
        inspect($raw-query)
    );

    @media #{$query} {
      @content;
    }
  } @else {
    @error 'No se ha encontrado un valor para `#{$breakpoint}`. '
         + 'Por favor, asegúrate que está definido en el mapa `$breakpoints`.';
  }
}


@mixin respond-between($lower, $upper) {

  // If both the lower and upper breakpoints exist in the map.
  @if map-has-key($breakpoints, $lower) and map-has-key($breakpoints, $upper) {

    // Get the lower and upper breakpoints.
    $lower-breakpoint: map-get($breakpoints, $lower);
    $upper-breakpoint: map-get($breakpoints, $upper);

    // Write the media query.
    @media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1)) {
      @content;
    }

    // If one or both of the breakpoints don't exist.
  } @else {

    // If lower breakpoint is invalid.
    @if (map-has-key($breakpoints, $lower) == false) {

      // Log a warning.
      @warn 'Your lower breakpoint was invalid: #{$lower}.';
    }

    // If upper breakpoint is invalid.
    @if (map-has-key($breakpoints, $upper) == false) {

      // Log a warning.
      @warn 'Your upper breakpoint was invalid: #{$upper}.';
    }
  }
}


@mixin iesupport() {

  @media all and (-ms-high-contrast:none)
  {
    /* IE11 */
    @content;
  /* IE10 */
    *::-ms-backdrop, .material-icons {
      @content;
    }
  }
}


