@import "src/style/abstracts/_abstract.scss";


body {
  margin: 0;
  font-family: 'Roboto', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // background: #062653 linear-gradient(90deg,#062653,#2ba3ce)!important;
  height: 100%;
  width: 100%;
}
html{
  height: 100%;
  width: 100%;
}
* {
  box-sizing: border-box;
}

.error-form{
  font-size: 0.86em;
  color: $palette-error;
}

.box{
  padding: 8px;
  background-color: $palette-light;
  box-shadow: box-shadow('soft');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: inherit;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}


h1,h2,h3,h4,h5,h6{
  color: tint($palette-dark, 10);
  font-weight: normal;
}
.form-control{
  margin-top: 7px!important;
  margin-bottom: 7px!important;
  box-sizing: border-box;
  max-width: 100%!important;
}

//button{
  //height: min-content;
//}

.box-info{
  background-color: tint($palette-dark, 95);
  padding: 15px;
  border-radius: 8pt;
}

a{
  color: tint($palette-secondary, 20);
  cursor: pointer;
}


.table{
  background-color: $palette-light;
  border: 1px solid tint($palette-dark, 40);
  table-layout: fixed;
  font-size:1.1em;
  border-collapse: initial;
  @include respond-between('phone', 'pc') {
    width: auto!important;
  }
  th{
    font-weight: bold;
  }
  .table-resalt{
    th{
      background-color: tint($palette-dark, 96);
    }
  }

}
