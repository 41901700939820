/* Black*/
$palette-light: #FFF;
/* white */
$palette-dark: #333;
/** Ink */
$palette-primary: #f5c067;
/* Posy */
$palette-secondary: #062653;
/* Embers */
$palette-terciary: hsla(11, 63%, 43%, 0.88);

$palette-green: hsla(114, 63%, 43%, 0.88);

$palette-error: #f44336;

$breakpoints: (
  'phone':  280px,
  'tablet':  720px,
  'pc':  1080px,
) !default;
