@import "src/style/abstracts/abstract";

.register-lead-form-block{
  & > div{
    //margin: 5px;
  }
}

.canvas-container{
  border: 1px solid tint($palette-dark, 30);
  display: table;
  margin:0 auto;
}

.phone-field{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 15px;
}

.help-icon-button{
  margin-right: 70%;
  margin-bottom: 12%;
}

.help-warning-text{
  color: blue;
  font-style: italic;
}

