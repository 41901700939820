@import "src/style/abstracts/_abstract.scss";


.header{
    padding: 12px;
    display: grid;
    grid-template-columns: auto auto 1fr;
    @include respond-between('phone','pc') {
        grid-template-columns: auto auto auto 1fr;
    }
    background-color: $palette-light;
    box-shadow: box-shadow('medium');
    border-radius: 10px 10px 0 0;
    @include respond-between('phone', 'pc') {
        padding: 5px;
        border-radius: 0;
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    h1{
        padding: 0;
        //vertical-align: baseline;
        margin: 15px 0 0 20px;
        font-size: 1.5em;
        color: tint($palette-dark, 0.5)
    }

}

.header-icon{
    font-size: 2.5em;
    color: $palette-secondary;
    margin-right: 30px;
    cursor: pointer;
    display: none;
    @include respond-between('phone','pc') {
        display: block;
    }
}
.header-logo{
    display: block;
    max-width: 180px;
    max-height: 80px;
}
.iberdrola-logo{
    margin-left: 20px;
    display: block;
    max-width: 150px;
}

