@import "src/style/abstracts/_abstract.scss";

.calendar-container{
  font-size: 1.1em;
}
.calendar-week-days{
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(7, 1fr);
}
.calendar-week-header-day{
  color: rgba(0, 0, 0, 0.87);
  width: 46px;
  height: 50px;
  align-self: center;
  justify-self: center;
  border: 1px transparent;
  background-color: transparent;
  outline: none;
}

.calendar-days{
}

.calendar-week-days-day{

}

.calendar-week{
  display: grid;
  align-content: center;
  grid-template-columns: repeat(7, 1fr);

}

.calendar-week-days-day-container {
  @extend .calendar-week-header-day;
  width: 46px;
  height: 50px;

}

.calendar-week-days-day {
  @extend .calendar-week-header-day;
}


.calendar-week-days-day-filled {
  border-radius: 50%;
  cursor: pointer;
  width: 100%;
  border: 1px transparent;
  background-color: transparent;
  height: 100%;
  outline: 0;
  &:hover{
    background-color: tint($palette-dark, 90);
  }
}

.selected{
  button{
    background-color: tint($palette-dark, 70);
    &:hover{
      background-color: tint($palette-dark, 70);
    }
  }
}

.hovered-range{
  background-color: tint($palette-dark, 95);
}


.selected-range{
  background-color: tint($palette-dark, 90);
}
.current-day{
  button{
    border: 1px solid tint($palette-dark, 20)
  }
}
