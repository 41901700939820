@import "src/style/abstracts/abstract";

.login-container{
  padding-top: 8%;
  @include respond-between('phone', 'pc') {
    padding-top: 20%;
  }
}
.login-main{
  width: 30%;
  box-shadow: box-shadow('medium');
  display: table;
  padding: 15px;
  border-radius: 10px;
  background-color: $palette-light;
  margin: 0 auto!important;
  @include respond-between('phone', 'pc') {
    display: block;
    width: 100%;
    border-radius: 0;
  }
  .login-main-logos{
    display: table;
    margin: 0 auto;
    .login-header-logo{
      max-width: 180px;
      display: inline-block;
    }
    .login-header-iberdrola-logo{
      max-width: 150px;
      display: inline-block;
      margin-left: 20px;
    }
    h1{
      display: inline-block;
    }
  }
}
