@import "src/style/abstracts/_abstract.scss";



.alert-container{
  position: fixed;
  bottom: 10%;
  left: 15%;
  width: 60%;
  border: 1px solid tint($palette-dark,50);
  @include respond-between('phone', 'pc') {
    width: 95%;
    left: 5px;
    right: 5px;
    bottom: 15px;
  }
}
