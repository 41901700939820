@import "src/style/abstracts/_abstract.scss";

.left-panel-container{
  overflow: auto;
  scroll-behavior: smooth;
}
.left-panel{
  background-color: $palette-light;
  height: 100%;
  border-radius: 0 0 10px 10px;
  @include respond-between('phone', 'pc') {
    border-radius: 0;
  }
  .left-panel-menu{
    margin: 0;
    padding: 0;
    .left-panel-header{
      background-color: mix(#062653, $palette-light, 55);
      color: $palette-light!important;
      margin-left: 0;
      font-size: 1.2em;
      padding: 18px;
    }
    .left-panel-active{
      background-color: tint(#062653, 95);
      color: mix(#062653, $palette-light, 85);
      font-weight: bolder;
    }
    li{
      font-size: 1em;
      color: mix(#062653, $palette-light, 70);
      list-style: none;
      a{
        padding: 18px;
        display: block;
        text-decoration: none;
        color:inherit;
        &:visited{
          color:inherit;
        }
        &:hover{
          background-color: tint(#062653, 95);
        }
      }
      i{
        margin-right: 10px;
      }
    }
  }
}
.left-panel-submenu{
  margin: 0;
  padding: 0;
  margin-left: 15px;
  display: none;
}

.left-panel-subpanel-active{
  background-color: tint(#062653, 95);
  color: mix(#062653, $palette-light, 85);
  .left-panel-submenu{
    display: block;
  }
}
.left-subpanel-active{
  font-weight: bolder;
}
.left-subpanel-child-active{
  font-weight: bolder;
}

.left-panel-user{
  display: grid;
  grid-template-columns: 1fr 3fr;
  padding: 15px;

}
.left-panel-user-info{
  h1{
    margin: 0;
    padding: 0;
    font-weight: normal;
    color: tint($palette-dark, 10);
  }
  h4{
    margin: 0;
    padding: 0;
    font-weight: normal;
    color: tint($palette-dark, 30);
  }
}
