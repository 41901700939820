@import "src/style/abstracts/_abstract.scss";


.App {
    position: relative;
    min-height: 100vh;
    padding: 60px;
    @include respond-between('phone', 'pc') {
        padding: 0;
    }
}
