@import "src/style/abstracts/_abstract.scss";


.MuiInputBase-input{
  color: tint($palette-dark, 30);
}

.lead-details-disabled{
  color: tint($palette-dark, 30)!important;
}

.sign-image{
  display: table;
  margin: 0 auto;
}
